@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}

.scaleopsShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
